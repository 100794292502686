<template>
  <div>
    <h4>
      Speaking:
      <span>
        <b style="color: #ff8920;"> {{ score }}</b>
      </span>
    </h4>
    <div class="row">
      <div
        v-for="(item, index) in list"
        :key="item.exam_question_id"
        class="col-xs-12 col-sm-12 col-md-6 row speaking-answer"
      >
        <div class="col-sm-1">
          <b>{{ index + 1 }}.</b>
        </div>
        <div class="col-sm-5">
          <audio
            v-if="item.user_answer"
            :id="item.exam_question_id"
            :src="item.user_answer[0]"
          />
          <div class="pgs">
            <div
              class="pgs-play"
              :style="{ width: (item.progress / item.time) * 100 + '%' }"
            >
              <div class="controls">
                <span class="played-time">{{ getTime(item.progress) }}</span>
                <button class="play-pause" @click="controlAudio(item)">
                  <span v-show="item.canPlay == true">
                    <i class="fas fa-play"></i>
                  </span>
                  <span v-show="item.canPlay == false">
                    <i class="fas fa-pause"></i>
                  </span>
                </button>
                <span class="audio-time" id="audioTime">
                  {{ getTime(item.time) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <el-dropdown size="small" @command="changeSpeed" :key="index">
            <span class="audio-speed">
              <b class="text-success">Speed: {{ item.speed }} X</b>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(it, index) in multipleArray"
                :key="index"
                :command="composeValue(it, item)"
              >
                {{ it }} X
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a
            style="float:right"
            class="text-success"
            @click="getResolve(item.toefl_question_id)"
          >
            <i class="far fa-eye"></i>
          </a>
        </div>
        <div class="Audio col-sm-2 text-center">
          <el-select
            v-if="
              profile.all_roles.indexOf('super-admin') > -1 ||
                profile.all_roles.indexOf('staff') > -1 ||
                profile.all_roles.indexOf('teacher') > -1
            "
            v-model="item.score"
            placeholder="Score"
            @change="saveScore(item.user_exam_answer_id, item.score)"
          >
            <el-option
              v-for="item in score_list"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div v-else>
            Score:<b> {{ item.score }} </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["list", "score"],
  data() {
    return {
      score_list: [0, 1, 2, 3, 4],
      multipleArray: [0.8, 1, 1.25, 2, 2.5]
    };
  },
  computed: {
    ...mapState("user", ["profile"])
  },

  watch: {
    list() {
      this.getAudio();
    }
  },

  async mounted() {
    await this.getProfile();
    this.getAudio();
  },

  methods: {
    getAudio() {
      let speaking_list = this.list;
      speaking_list.forEach(element => {
        let audio = document.getElementById(element.exam_question_id);
        audio.load();
        audio.oncanplay = function() {
          element.progress = audio.currentTime;
          element.time = audio.duration;
          element.speed = 1;
        };
        this.$set(element, "progress", 20);
      });
    },
    composeValue(it, item) {
      return {
        it: it,
        item: item
      };
    },
    changeSpeed(command) {
      let audio = document.getElementById(command.item.exam_question_id);
      audio.playbackRate = command.it;
      command.item.speed = command.it;
    },
    controlAudio(item) {
      let audio = document.getElementById(item.exam_question_id);
      audio.addEventListener(
        "timeupdate",
        function() {
          //更新进度条
          item.progress = audio.currentTime;
        },
        false
      );
      audio.addEventListener(
        "ended",
        function() {
          //播放完成
          item.progress = 0;
          item.canPlay = true;
        },
        false
      );
      if (item.canPlay) {
        audio.play();
        item.canPlay = false;
      } else {
        audio.pause();
        item.canPlay = true;
      }
    },
    getTime(seconds_remaining) {
      var minute;
      var second;
      minute = Math.floor(seconds_remaining / 60);
      second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    getResolve(question_id) {
      this.$parent.getResolve(question_id);
    },
    saveScore(user_exam_answer_id, score) {
      this.$parent.saveScore(user_exam_answer_id, score);
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.speaking-answer {
  line-height: 40px;
}
div a {
  cursor: pointer;
}
</style>
