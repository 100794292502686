<template>
  <div>
    <h4>
      Reading:
      <span
        ><b style="color: #ff8920;">{{ score }}</b> ({{ raw }}/{{
          count
        }})</span
      >
    </h4>
    <div v-for="(passage, index) in list" :key="index">
      <h5>Passage {{ index }}</h5>
      <table class="table table-bordered" style="width:100%">
        <thead>
          <tr class="rnum_1">
            <th class="green-td">Num</th>
            <th class="green-td">Your Answer</th>
            <th class="green-td">Correct Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="answer in passage" :key="answer.order">
            <td class="green-td">{{ answer.order }}</td>
            <td>
              <a
                @click="getResolve(answer.toefl_question_id)"
                v-html="
                  getMyAnswer(
                    answer.user_answer,
                    answer.correct_answer,
                    answer.is_correct
                  )
                "
              >
              </a>
            </td>
            <td>
              <a
                @click="getResolve(answer.toefl_question_id)"
                v-html="getCorrectAnswer(answer.correct_answer)"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflMixin from "@/mixins/toefl.js";

export default {
  components: {},

  mixins: [ToeflMixin],

  props: ["list", "score", "raw", "count"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    getResolve(question_id) {
      this.$parent.getResolve(question_id);
    }
  }
};
</script>

<style scoped>
.table {
  position: relative;
  width: 100%;
  display: block;
}
thead {
  float: left;
}
tbody tr {
  display: inline-block;
}
th,
td {
  height: 45px;
  display: block;
}
th {
  width: 150px;
}
td {
  width: 75px;
}
.table td {
  width: 110px;
}
td a {
  cursor: pointer;
}
</style>
