<template>
  <div class="p-4">
    <div class="row sat">
      <div class="col-md-5 col-sm-12 sat_left">
        <p>TOEFL</p>
      </div>
      <div class="col-md-7 col-sm-12 sat_right row">
        <div class="col-sm-8">
          <ul>
            <li>
              Name:
              <span
                ><a href="">{{
                  exam_info.student.first_name +
                    " " +
                    exam_info.student.last_name
                }}</a></span
              >
              &nbsp;
              <el-tooltip
                v-if="isAdmin"
                class="item"
                effect="dark"
                content="將成績單發送到學生郵箱"
                placement="top"
              >
                ><el-button
                  type="text"
                  size="medium"
                  @click="sendEmail"
                  style="font-size:18px"
                  ><i class="far fa-envelope"></i></el-button
              ></el-tooltip>
            </li>
            <li>
              Test Name: <span>{{ exam_info.exam_title }}</span>
            </li>
            <li>
              Test Date: <span>{{ exam_info.exam_date }}</span>
            </li>
          </ul>
        </div>
        <div class="col-sm-4 img mb-4">
          <a href="/">
            <img
              src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
              class="logo logo_image"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <score-card
          :id="user_exam_id"
          :user_score="user_score"
          :scoreDate="exam_info.exam_date"
        ></score-card>
      </div>
      <div class="col-sm-12 col-md-6">
        <best-score-card :user_score="user_score"></best-score-card>
      </div>
    </div>
    <hr class="section" />
    <score-table :user_evaluation="user_evaluation"></score-table>
    <div v-if="!isEmpty(reading_list)">
      <hr class="section" />
      <reading
        :list="reading_list"
        :score="user_score.reading_score"
        :raw="user_score.reading_raw"
        :count="user_score.reading_question_count"
      ></reading>
    </div>
    <div v-if="!isEmpty(listening_list)">
      <hr class="section" />
      <listening
        :list="listening_list"
        :score="user_score.listening_score"
        :raw="user_score.listening_raw"
        :count="user_score.listening_question_count"
      ></listening>
    </div>
    <div v-if="!isEmpty(speaking_list)">
      <hr class="section" />
      <speaking
        :score="user_score.speaking_score"
        :list="speaking_list"
      ></speaking>
    </div>
    <div v-if="!isEmpty(writing_list)">
      <hr class="section" />
      <writing :score="user_score.writing_score" :list="writing_list"></writing>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import "./style/reportcard.css";
import role from "@/mixins/role.js";
import ScoreCard from "./ScoreCard";
import BestScoreCard from "./BestScoreCard";
import ScoreTable from "./ScoreTable";
import Reading from "./Reading";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import TOEFL from "@/apis/toefl";
import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: "TOEFL Test Result - Ivy-Way Academy"
    };
  },

  components: {
    ScoreCard,
    BestScoreCard,
    ScoreTable,
    Reading,
    Listening,
    Speaking,
    Writing
  },

  mixins: [role],

  props: [],
  data() {
    return {
      user_evaluation: {},
      user_score: {},
      topic: [],
      exam_info: { student: { first_name: "", last_name: "" } }
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    user_exam_id() {
      return this.$route.query.id;
    },
    reading_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "reading"]),
        "passage"
      );
    },
    listening_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "listening"]),
        "passage"
      );
    },
    speaking_list() {
      let speaking_list = _.filter(this.topic, ["skill_name", "speaking"]);
      speaking_list.forEach(element => {
        this.$set(element, "canPlay", true);
        this.$set(element, "width", 0);
        this.$set(element, "progress", 20);
        this.$set(element, "time", 60);
        this.$set(element, "speed", 1);
      });
      return speaking_list;
    },
    writing_list() {
      return _.filter(this.topic, ["skill_name", "writing"]);
    }
  },
  watch: {},

  mounted() {
    this.getTranscript();
  },

  methods: {
    async getTranscript() {
      const res = await TOEFL.getTranscript(this.user_exam_id);
      this.user_evaluation = res.user_evaluation;
      this.user_score = res.user_score;
      this.topic = res.topic;
      this.exam_info = res.exam_info;
    },
    isEmpty(object) {
      return _.isEmpty(object);
    },
    async sendEmail() {
      const res = await TOEFL.sendEmail(this.user_exam_id);
      this.$message({
        message: "發送成功！",
        type: "success"
      });
    },
    getResolve(question_id) {
      const { href } = this.$router.resolve({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id: question_id
        }
      });
      window.open(href, "_blank");
    },
    async saveScore(user_exam_answer_id, score) {
      await TOEFL.gradeScore(user_exam_answer_id, {
        score: score
      });
      this.getTranscript();
    }
  }
};
</script>

<style scoped>
hr.section {
  border: 0;
  border-bottom: 3px solid #43a06b;
  margin: 20px 0;
}
.reportcard {
  padding: 20px;
}
.sat_left > p:first-child {
  font-size: 120px;
  font-weight: 700;
  color: #43a06b;
  line-height: 120px;
  margin-bottom: 0px;
}
</style>
