var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v(" Reading: "),_c('span',[_c('b',{staticStyle:{"color":"#ff8920"}},[_vm._v(_vm._s(_vm.score))]),_vm._v(" ("+_vm._s(_vm.raw)+"/"+_vm._s(_vm.count)+")")])]),_vm._l((_vm.list),function(passage,index){return _c('div',{key:index},[_c('h5',[_vm._v("Passage "+_vm._s(index))]),_c('table',{staticClass:"table table-bordered",staticStyle:{"width":"100%"}},[_vm._m(0,true),_c('tbody',_vm._l((passage),function(answer){return _c('tr',{key:answer.order},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(answer.order))]),_c('td',[_c('a',{domProps:{"innerHTML":_vm._s(
                _vm.getMyAnswer(
                  answer.user_answer,
                  answer.correct_answer,
                  answer.is_correct
                )
              )},on:{"click":function($event){return _vm.getResolve(answer.toefl_question_id)}}})]),_c('td',[_c('a',{domProps:{"innerHTML":_vm._s(_vm.getCorrectAnswer(answer.correct_answer))},on:{"click":function($event){return _vm.getResolve(answer.toefl_question_id)}}})])])}),0)])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("Num")]),_c('th',{staticClass:"green-td"},[_vm._v("Your Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")])])])
}]

export { render, staticRenderFns }