<template>
  <div>
    <div class="reading">
      <table class="table table-bordered performance_descriptor">
        <thead>
          <tr>
            <th class="green-td nowrap">Skills</th>
            <th class="green-td level">Level</th>
            <th class="green-td">Your Performance</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in user_evaluation" :key="item.name">
            <td>
              <b>{{ L.capitalize(item.name) }}</b>
              <h3 class="score" v-html="item.score"></h3>
            </td>
            <td class="score_level" v-html="item.level_desc"></td>
            <td class="your_performance" v-html="item.desc"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";

export default {
  components: {},

  mixins: [],

  props: ["user_evaluation"],
  data() {
    return {
      L: _
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.level,
.nowrap {
  width: 150px;
}
.score {
  color: #ff8920;
}
</style>
