<template>
  <div class="score_border">
    <div class="score_border_head">
      <div>
        <h4>MyBest Scores</h4>
        <h6>
          Your highest section scores.
        </h6>
      </div>
    </div>
    <div class="row" style="padding: 0 20px">
      <div class="col-sm-4">
        <h5 class="text-center">Sum of Highest Section Scores</h5>
        <div>
          <div>
            <div class="text-center progress_total">{{ total }}</div>
          </div>
          <h5 class="text-center">out of 120</h5>
        </div>
      </div>
      <div class="col-sm-8">
        <div
          class="row score_section rmax_score_progress"
          v-if="user_score.max_reading.max_reading_score !== undefined"
        >
          <div class="col-sm-5">
            <h5>
              Reading:
              <a class="text-success">{{
                user_score.max_reading.max_reading_score
              }}</a>
            </h5>
            <a :href="'/toefl/transcript?id=' + user_score.max_reading.id">{{
              getMoment(user_score.max_reading.created_at)
            }}</a>
          </div>
          <div class="col-sm-7">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{
                  'margin-left': getLeft(
                    user_score.max_reading.max_reading_score
                  )
                }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>30</b>
            </div>
          </div>
        </div>
        <div class="row score_section lmax_score_progress">
          <div class="col-sm-5">
            <h5>
              Listening:
              <a class="text-success">{{
                user_score.max_listening.max_listening_score
              }}</a>
            </h5>
            <a :href="'/toefl/transcript?id=' + user_score.max_listening.id">{{
              getMoment(user_score.max_listening.created_at)
            }}</a>
          </div>
          <div class="col-sm-7">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{
                  'margin-left': getLeft(
                    user_score.max_listening.max_listening_score
                  )
                }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>30</b>
            </div>
          </div>
        </div>

        <div class="score_section smax_score_progress">
          <div v-if="true" class="row">
            <div class="col-sm-5">
              <h5>
                Speaking:
                <a class="text-success">{{
                  user_score.max_speaking.max_speaking_score
                }}</a>
              </h5>
              <a :href="'/toefl/transcript?id=' + user_score.max_speaking.id">{{
                getMoment(user_score.max_speaking.created_at)
              }}</a>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{
                    'margin-left': getLeft(
                      user_score.max_speaking.max_speaking_score
                    )
                  }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-sm-5">
              <h4>Speaking: -</h4>
            </div>
          </div>
        </div>

        <div class="score_section wmax_score_progress">
          <div v-if="true" class="row">
            <div class="col-sm-5">
              <h5>
                Writing:
                <a class="text-success">{{
                  user_score.max_writing.max_writing_score
                }}</a>
              </h5>
              <a :href="'/toefl/transcript?id=' + user_score.max_writing.id">{{
                getMoment(user_score.max_writing.created_at)
              }}</a>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{
                    'margin-left': getLeft(
                      user_score.max_writing.max_writing_score
                    )
                  }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-sm-5">
              <h5>Writing: -</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";

export default {
  components: {},

  mixins: [],

  props: ["isBestScore", "user_score"],
  data() {
    return {
      total: 0
    };
  },
  computed: {},
  watch: {
    user_score() {
      let total = 0;
      total =
        this.user_score.max_reading.max_reading_score +
        this.user_score.max_listening.max_listening_score +
        this.user_score.max_speaking.max_speaking_score +
        this.user_score.max_writing.max_writing_score;
      this.total = total;
    }
  },

  mounted() {},

  methods: {
    getLeft(score) {
      let progress_width = document.getElementsByClassName("score_progress")[0];
      let width = 140;
      if (progress_width != undefined) {
        width = progress_width.offsetWidth;
      }
      return (score / 30) * width + 8 + "px";
    },
    getMoment(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
};
</script>

<style scoped>
.score_progress {
  margin: 0 5px;
  width: 140px;
}
.score_border_head h4,
.score_border_head h6 {
  color: #fff;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  margin: 15px 0 0 0;
  color: black;
}
</style>
