<template>
  <div>
    <div>
      <h4>
        Writing:
        <span>
          <b style="color: #ff8920;"> {{ score }}</b>
        </span>
      </h4>
    </div>
    <div v-for="(item, index) in list" :key="item.exam_question_id">
      <table class="table table-bordered" style="margin-top: 25px">
        <thead>
          <tr>
            <td class="green-td" style="width: 15%"></td>
            <td class="green-td">
              
              <a
                class="view_question"
                style="color:white !important;"
                @click="getResolve(item.toefl_question_id)"
              > Essay {{ index + 1 }}
                <i class="far fa-eye"></i>
              </a>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr id="a_article_1">
            <td>
              <div
                class="row"
                v-if="
                  profile.all_roles.indexOf('super-admin') > -1 ||
                    profile.all_roles.indexOf('staff') > -1 ||
                    profile.all_roles.indexOf('teacher') > -1
                "
              >
                <div class="col-sm-4 get_wscore get_wscore_1">
                  <span style="line-height:40px">Score: </span>
                </div>
                <div class="col-sm-8">
                  <el-select
                    v-model="item.score"
                    placeholder="Score"
                    @change="saveScore(item.user_exam_answer_id, item.score)"
                  >
                    <el-option
                      v-for="item in score_list"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-else>
                Score:<b> {{ item.score }} </b>
              </div>
            </td>
            <td style="text-align: left;">
              <div class="writing_answer_1">
                <div
                  class="writing-text"
                  v-html="setText(item.user_answer[0])"
                ></div>
                (<span style="font-weight:700"
                  >{{ getWordCount(item.user_answer[0]) }} words</span
                >)
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["list", "score"],
  data() {
    return {
      score_list: [0, 1, 2, 3, 4, 5]
    };
  },
  computed: {
    ...mapState("user", ["profile"])
  },
  watch: {},

  async mounted() {
    await this.getProfile();
  },

  methods: {
    getWordCount(string) {
      let word = 0;
      let count = 0;
      if (string == "" || string == undefined || string == null) {
        count = 0;
      } else {
        for (var i = 0; i < string.length; i++) {
          if (string[i] == " ") {
            word = 0;
          } else if (word == 0) {
            word = 1;
            count++;
          }
        }
      }
      return count;
    },
    getResolve(question_id) {
      this.$parent.getResolve(question_id);
    },
    saveScore(user_exam_answer_id, score) {
      this.$parent.saveScore(user_exam_answer_id, score);
    },
    setText(text) {
      if (text) {
        return text.replace(/\n/g, "<br />");
      }
      return "";
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
td a {
  cursor: pointer;
}
</style>
